'use client';
import { componentMap } from '@/components/sections';
import LazyMotionWrapper from '@/components/shared/LazyMotionWrapper';
import { QueryResponseInitial, useQuery } from '@sanity/react-loader';
import { SanityDocument, QueryParams } from 'next-sanity';
import React from 'react';
import { SetNavbarTextColor } from '@/components/global/modules';
export default function PreviewPage({
  initial,
  params,
  query,
  breadcrumbs,
  navbarBgColor,
  navbarOverlay,
}: {
  initial: QueryResponseInitial<any>;
  params: QueryParams;
  query: any;
  breadcrumbs: any;
  navbarBgColor?: string;
  navbarOverlay?: string;
}) {
  const { data: page } = useQuery<SanityDocument | null>(query, params, {
    initial,
  });

  const sections = page?.pageBuilder;

  return (
    <>
      {/* @ts-ignore */}
      <SetNavbarTextColor color={navbarBgColor} overlay={navbarOverlay} />
      <LazyMotionWrapper>
        {sections &&
          sections.map((section: any) => {
            const Component = componentMap[section._type];

            if (Component) {
              return (
                <Component
                  key={section.id}
                  data={{ breadcrumbs, ...section }}
                />
              );
            }
            return null;
          })}
      </LazyMotionWrapper>
    </>
  );
}
